import { createContext, useContext } from "react";

export interface GlobalNavPermissions {
  canAccessRecruit: boolean;
  canAccessHcpExperience: boolean;
  canAccessHcpTraining: boolean;
  canAccessHcpReputation: boolean;
  canAccessActivatedInsights: boolean;
  canAccessPinnacleRetain: boolean;
  canAccessPinnacleMain: boolean;
  canAccessPinnacleCAHPS: boolean;
}

export interface PermissionsContextState {
  permissions: GlobalNavPermissions;
}

export const PermissionsContext = createContext<PermissionsContextState>({
  permissions: {
    canAccessRecruit: false,
    canAccessHcpExperience: false,
    canAccessHcpTraining: false,
    canAccessHcpReputation: false,
    canAccessActivatedInsights: false,
    canAccessPinnacleRetain: false,
    canAccessPinnacleMain: false,
    canAccessPinnacleCAHPS: false,
  },
});

export function usePermissionsContext() {
  return useContext(PermissionsContext);
}
