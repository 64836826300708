import { Menu } from "@headlessui/react";
import { clsx } from "clsx";
import React, { Fragment, ReactNode } from "react";
import CloudUploadDropdownIcon from "../assets/dropdown/icon-cloud-upload-outline.svg";
import EditProfileDropdownIcon from "../assets/dropdown/icon-edit-profile.svg";
import LogoutDropdownIcon from "../assets/dropdown/icon-logout.svg";
import ProfileDropdownIcon from "../assets/dropdown/icon-profile.svg";
import ProfileIcon from "../assets/global-nav/tempProfile.jpg";
import {
  GlobalNavDropdown,
  GlobalNavDropdownMenuItem,
  GlobalNavDropdownMenuItems,
} from "../components/common/Dropdown";
import { CLICK_LOGOUT_EVENT_NAME, GlobalNavSite } from "../constants/constants";
import { useVariationLogoutUrl } from "../constants/strings";
import { useGlobalNavContext } from "../hooks/useGlobalNavContext";

const onClickLogout: React.MouseEventHandler<HTMLButtonElement> = (e) => {
  e.currentTarget.dispatchEvent(
    new CustomEvent(CLICK_LOGOUT_EVENT_NAME, {
      bubbles: true,
      composed: true,
      detail: { originalEvent: e },
    })
  );
};

const profileButtonSize = "h-12 w-12";

interface GlobalNavProfileDropdownProps {
  companyName: string;
  editProfileUrl?: string;
  fileUploadUrl?: string;
  hideProfileDropdown: boolean;
  profileDropdownChildren?: ReactNode;
  userName: string;
  logoutUrl?: string;
}

export function GlobalNavProfileDropdown({
  companyName,
  editProfileUrl,
  fileUploadUrl,
  hideProfileDropdown,
  profileDropdownChildren = null,
  logoutUrl,
  userName,
}: GlobalNavProfileDropdownProps) {
  const { site, variation } = useGlobalNavContext();
  const variationLogoutUrl = useVariationLogoutUrl(variation);

  if (hideProfileDropdown || site === GlobalNavSite.PIN) {
    return <div className={profileButtonSize} />;
  }

  return (
    <GlobalNavDropdown>
      <Menu>
        <div className="flex h-full items-center">
          <Menu.Button as={Fragment}>
            {({ open }) => (
              <button
                className={clsx(
                  `${profileButtonSize} rounded-full hover:bg-white/25`,
                  open && "bg-white/25"
                )}
              >
                {site === GlobalNavSite.HCP ? (
                  <img
                    className="block size-full rounded-full"
                    src={ProfileIcon}
                    alt="Profile"
                  />
                ) : (
                  <div className="flex size-full items-center justify-center rounded-full">
                    <ProfileDropdownIcon
                      // ml-1 because icon is not centered in svg
                      className="ml-1 h-6 w-auto"
                    />
                  </div>
                )}
              </button>
            )}
          </Menu.Button>
        </div>
        <GlobalNavDropdownMenuItems className="right-0 w-64">
          <GlobalNavDropdownMenuItem
            as="div"
            className="group pointer-events-none items-start"
            iconStart={<ProfileDropdownIcon className="size-6" />}
          >
            <div>
              <div className="text-lg">{userName}</div>
              <div className="text-sm text-gray-500 group-hover:text-inherit">
                {companyName}
              </div>
            </div>
          </GlobalNavDropdownMenuItem>
          {editProfileUrl && (
            <GlobalNavDropdownMenuItem
              href={editProfileUrl}
              iconStart={<EditProfileDropdownIcon className="size-6" />}
            >
              Edit Profile
            </GlobalNavDropdownMenuItem>
          )}
          {fileUploadUrl && (
            <GlobalNavDropdownMenuItem
              href={fileUploadUrl}
              iconStart={<CloudUploadDropdownIcon className="size-6" />}
            >
              File Uploads
            </GlobalNavDropdownMenuItem>
          )}
          {profileDropdownChildren}
          <GlobalNavDropdownMenuItem
            href={logoutUrl || variationLogoutUrl}
            onClick={onClickLogout}
            iconStart={<LogoutDropdownIcon className="size-6" />}
          >
            Logout
          </GlobalNavDropdownMenuItem>
        </GlobalNavDropdownMenuItems>
      </Menu>
    </GlobalNavDropdown>
  );
}
