import React, { ReactNode } from "react";
import { invariant } from "ts-invariant";
import CheckCircleIcon from "../../assets/modal/check-mark-icon.svg";
import BestSeniorLivingUSNewsImage from "../../assets/modal/icon-best-senior-living-us-news.png";
import GreatPlaceToWorkCertifiedImage from "../../assets/modal/icon-great-place-to-work-certified.png";
import { GlobalNavVariation } from "../../constants/constants";
import { useCrossModalContext } from "../../hooks/useCrossModalContext";

const strongClassName = "";
const spanClassName = "ai:text-ai-blue-800/[65%]";

function BulletFeatureListItem({ children }: { children: ReactNode }) {
  return (
    <li className="relative pl-7">
      <CheckCircleIcon className="absolute left-0 top-0.5" />
      {children}
    </li>
  );
}

export function CrossModalFeatures() {
  const { crossModalVariation } = useCrossModalContext();

  invariant(crossModalVariation);

  return (
    <div>
      {crossModalVariation === GlobalNavVariation.HCP_EXPERIENCE && (
        <ul className="space-y-4">
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Receive unbiased employee & client feedback&mdash;
            </strong>
            <span className={spanClassName}>
              we will call a sample of your clients and care professionals and
              deliver direct feedback and quantitative satisfaction metrics to
              your dashboard.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Identify promoters&mdash;
            </strong>
            <span className={spanClassName}>
              with Net Promoter Score Analysis (NPS) you will be able to
              identify who is most likely to promote your business and those
              that are less satisfied to either ask for reviews or improve their
              experience.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              View trends and compare to industry benchmarks&mdash;
            </strong>
            <span className={spanClassName}>
              see how you&apos;ve improved overtime and how you compare to other
              businesses.
            </span>
          </BulletFeatureListItem>
        </ul>
      )}
      {crossModalVariation === GlobalNavVariation.HCP_REPUTATION && (
        <ul className="space-y-4">
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Request Reviews Through SMS & Email&mdash;
            </strong>
            <span className={spanClassName}>
              send a mass blast or decide exactly who to ask for reviews based
              on satisfactions scores from surveys
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Track Your Overall Rating Across All Review Sources&mdash;
            </strong>
            <span className={spanClassName}>
              as 88% of consumers read reviews about local businesses, it is
              important to know how you are being talked about
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Keep An Eye on Your Competitors&mdash;
            </strong>
            see how you stack up so you can stand out
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Monitor & Respond&mdash;
            </strong>
            <span className={spanClassName}>
              view and respond to all reviews in the same place. Quickly
              responding to reviews shows you care about feedback
            </span>
          </BulletFeatureListItem>
        </ul>
      )}
      {crossModalVariation === GlobalNavVariation.HCP_TRAINING && (
        <ul className="space-y-4">
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Access a library of 500+ RN-developed courses with
            </strong>
            <span className={spanClassName}>
              {" "}
              in-service, online, or mobile options
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>Streamline training</strong>
            <span className={spanClassName}>
              {" "}
              for compliance, onboarding, and ongoing training
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Easy program management&mdash;
            </strong>
            <span className={spanClassName}>
              build custom learning paths, assign courses, track completions,
              and access custom reporting from your dashboard
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Differentiate yourself and upskill staff
            </strong>
            <span className={spanClassName}>
              {" "}
              with specialized learning paths including Alzheimer&apos;s and
              Dementia training and professional development courses
            </span>
          </BulletFeatureListItem>
        </ul>
      )}
      {crossModalVariation === GlobalNavVariation.ACTIVATED_INSIGHTS && (
        <div className="flex flex-col items-center lg:flex-row">
          <div className="mb-6 flex w-1/2 shrink-0 items-center lg:w-1/4">
            <img
              className="w-[40%]"
              src={GreatPlaceToWorkCertifiedImage}
              alt="Great Place to Work Certified USA"
            />
            <div className="w-[10%]" />
            <img
              className="w-[50%]"
              src={BestSeniorLivingUSNewsImage}
              alt="Best Senior Living US News"
            />
          </div>
          <ul className="space-y-4 lg:pl-6">
            <BulletFeatureListItem>
              <strong className={strongClassName}>
                Become Great Place to Work certified&mdash;
              </strong>
              <span className={spanClassName}>
                increase job applicants by 20%, increase customer and employee
                referrals, and improve employee retention for your home-based or
                senior care organization
              </span>
            </BulletFeatureListItem>
            <BulletFeatureListItem>
              <strong className={strongClassName}>
                U.S. News Best Senior Living&mdash;
              </strong>
              <span className={spanClassName}>
                earn bragging rights as a top provider by the U.S. News and
                World Report
              </span>
            </BulletFeatureListItem>
            <BulletFeatureListItem>
              <strong className={strongClassName}>
                Access Insights&mdash;
              </strong>
              <span className={spanClassName}>
                access high-impact engagement and satisfaction insights to
                correspond with your certification and survey program through
                deep-dive analytics
              </span>
            </BulletFeatureListItem>
          </ul>
        </div>
      )}
      {crossModalVariation === GlobalNavVariation.PINNACLE_RETAIN && (
        <ul className="space-y-4">
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Survey new hires and identify at&mdash;
            </strong>
            <span className={spanClassName}>
              risk employees—check in with employees at vital points in their
              first 100 days, send a 6-month follow-up, and send annual
              engagement surveys.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Prompt managers to check in with staff&mdash;
            </strong>
            <span className={spanClassName}>
              daily emails give managers bite-size, high-impact tasks like
              greeting a new employee, responding to their survey, or
              celebrating a 100-day mark.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Drop individual turnovers in half&mdash;
            </strong>
            <span className={spanClassName}>
              employees who fill out at least one survey are half as likely to
              leave. When managers engage with the feedback, individual turnover
              drops by 65%.
            </span>
          </BulletFeatureListItem>
        </ul>
      )}
      {crossModalVariation === GlobalNavVariation.PINNACLE_QI && (
        <ul className="space-y-4">
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Receive in-depth customer feedback and discharge surveys&mdash;
            </strong>
            <span className={spanClassName}>
              we&apos;ll interview seniors and their family members to
              understand your customers' experiences and identify opportunities
              for improvement.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Solve important issues faster&mdash;
            </strong>
            <span className={spanClassName}>
              get notified immediately if a serious issue is identified.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Get a big-picture view of customer satisfaction&mdash;
            </strong>
            <span className={spanClassName}>
              view high-level satisfaction trends for individual and multiple
              locations and compare against national averages.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Streamline the follow-up process&mdash;
            </strong>
            <span className={spanClassName}>
              tag specific users, create tasks to follow up on feedback, and
              verify that tasks are getting completed.
            </span>
          </BulletFeatureListItem>
        </ul>
      )}
      {crossModalVariation === GlobalNavVariation.PINNACLE_CAHPS && (
        <ul className="space-y-4">
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              High response rates&mdash;
            </strong>
            <span className={spanClassName}>
              get the data needed to compare against industry benchmarks.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Clear monthly reports&mdash;
            </strong>
            <span className={spanClassName}>
              access survey scores for 3-month, 12-month, state, and national
              averages, along with eligible and completed survey counts.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Detailed insights&mdash;
            </strong>
            <span className={spanClassName}>
              drilldown on specific CAHPS measurements such as communication
              between providers & patients, and specific care issues.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Automated survey submission&mdash;
            </strong>
            <span className={spanClassName}>
              save time by automating quarterly CAHPS hospice data submissions
              to CMS.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>Real-time alerts&mdash;</strong>
            <span className={spanClassName}>
              get notified immediately if a respondent comments on litigation,
              medication issues, or other urgent topics.
            </span>
          </BulletFeatureListItem>
        </ul>
      )}
      {crossModalVariation === GlobalNavVariation.RECRUIT && (
        <ul className="space-y-4">
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Automated Outreach&mdash;
            </strong>
            <span className={spanClassName}>
              Engage candidates 24/7, ensuring your're the first to schedule
              critical interviews.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Real-Time Syncing&mdash;
            </strong>
            <span className={spanClassName}>
              No more phone tag. Recruit aligns with your availability in real
              time.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Automated Scheduling&mdash;
            </strong>
            <span className={spanClassName}>
              Increase interview numbers and reduce hiring times by 70% or more.
            </span>
          </BulletFeatureListItem>
          <BulletFeatureListItem>
            <strong className={strongClassName}>
              Re-Engagement Campaigns&mdash;
            </strong>
            <span className={spanClassName}>
              Be the first to catch returning candidates in the job market.
            </span>
          </BulletFeatureListItem>
        </ul>
      )}
    </div>
  );
}
